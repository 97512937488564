/* button module: core */
// TODO: make more logical separation between core and theme styles

// button,
// input[type="button"],
// input[type="submit"] {
//   background-color: transparent;
// }

.btn,
.button-icon {
  background-color: transparent;
  width: max-content;
  display: flex;
  text-transform: uppercase;
  position: relative;
  overflow: hidden; // clip box for sliding background
  padding: 10*$pxToRem 20*$pxToRem;
  min-height: 36*$pxToRem;
  min-width: 120px;
  //padding-bottom: 7px;
  align-items: center;
  justify-content: center;
  z-index: 0;
  font-family: $body-font;
  font-weight: 300;
  color: $color-black;
  border: 1px solid $color-black;
  transition: all $transition-default;
  @include media-mobile-only {
    min-height: auto;
  }
  //&::after {
  //  display: block;
  //  content: "";
  //  position: absolute;
  //  bottom: 0;
  //  background-color: $color-accent;
  //  width: 100%;
  //  height: 7px;
  //  z-index: -1;
  //  opacity: 1;
  //  transition: all $transition-default;
  //  // offset colored background box by default (clipped by parent)
  //  transform: translateX(0);
  //}
  &.full {
    background-color: $color-black;
    color: $color-white;
    &:hover {
      background-color: $color-white;
      color: $color-black!important;
      border-color: $color-black!important;
    }
  }
  &.box {
    overflow: visible;
    margin-bottom: 139px;
    &:after {
      content: '';
      background-color: $color-black;
      width: 1px;
      height: 140px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -140px;
      margin: 0 auto;
      z-index: 10;
    }
    &:hover {
    }
  }

  &:hover {
    background-color: $color-black;
    color: $color-white;
    &::after {
      //background-color: $color-accent;
      //opacity: 0;
      // remove offset (i.e. colored box appears to slide all the way out)
      //transform: translateX(100%);
    }
  }
}

.button {
  // Plus sign preceding button text
  //&::before {
  //  content: "+";
  //  padding-right: 4*$pxToRem;
  //}
}

.padding-section-content .button:last-child {
  @include media-mobile-only {
    margin-bottom: $padding-mobile;
  }
}

.button-icon {
  svg {
    display: block;
    width: 16*$pxToRem;
    height: 16*$pxToRem;
    margin-right: 10*$pxToRem;
  }
}

.inverted a.btn,
.inverted a.button-icon,
a.btn.inverted,
a.button-icon.inverted {
  color: $color-white;
  border: 1px solid $color-white;
  transition: all $transition-default;
  &:hover {
    background-color: $color-white;
    color: $color-black;
  }
  &.full {
    background-color: $color-white;
    color: $color-black;
    &:hover {
      background-color: $color-black;
      color: $color-white;
      border-color: $color-black;
    }
  }
  &.box {
    &:after {
      background-color: $color-white;
    }
  }
}

a.svg, .svg {
  position: relative;
  &.inverted {
    svg {
      stroke: $color-white;
    }
  }
  svg {
    position: absolute;
    top: -1.5rem;
    left: 50%;
    right: 0;
    bottom: 0;
    pointer-events: none;
    fill: none;
    stroke: $color-gray-11;
    stroke-width: 0.5px;
    margin: 0 auto;
    transform: translateX(-50%);
    path {
      stroke-dasharray: 1;
      stroke-dashoffset: 1;
      transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);

    }
  }
  &:hover {
    svg {
      path {
        stroke-dashoffset: 0;
        transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
        transition-duration: 0.4s;
      }
    }
  }
}