#cost .title {
    margin-left: 11rem;
    margin-top: -3.5rem;
}

#inner-content{
    a{
        color: #000;
        text-decoration: underline;
        &:hover{
            color: #2164b2;
        }
    } 
    .btn{
        text-decoration: none;
        &:hover{
            color: #fff;
        }
    }
    p:not(:first-of-type){
        margin-bottom: 2rem;
        margin-top: 0;
    }
}

.page-id-6558 .section__banner{
    background-position: bottom;
}

.mb-0{
    margin-bottom: 0!important;
}

.pt-2{
    padding-top: 2rem;
}

.heading__breadcrumbs {
    ol {
        li{
            &:first-child, &:nth-child(2)::before{
                @include media-mobile-only {
                    display: none;
                }
            }
        }
    }
    ol li, ol li a{
        @include media-mobile-only {
            font-weight: normal;
            font-size: 0.8rem;
        }
    }  
    a{
        &:after {
            @include media-mobile-only {
                width: 0;
            }
	    }
    }
}
.section__banner{
    nav{
        ol{
            @include media-mobile-only {
                gap: 0 1.5rem;
                padding-bottom: 1.2rem;
                padding-top: 1rem;
            }
            li, a{
                @include media-mobile-only {
                    font-size: 0.75rem!important;
                }
            }
        }
    }   
}

body .uwy.userway_p5 .uai {
    @include media-mobile-only {
        bottom: 50px!important;
    }
}

.innerpage{
    .section__banner--button{
        .btn{
            @include media-mobile-only {
                padding: .625rem 0.25rem;
            }
            &:nth-child(1) {
                color: $color-white;
                border-color: $color-white;
                &:hover {
                    border-color: $color-black;
                }
            }
        }
    }
}

.section__assoc{
    .grid-4-col{
        grid-template-columns: repeat(4,auto);
        justify-content: space-between;
        @include media-mobile-only {
            grid-template-columns: repeat(2,auto);
            justify-content: space-evenly;
        }
        img{
            @include media-mobile-only {
                max-height: 85px!important;
                padding-bottom: 2rem;
            }
        }
    }
}

.out-of-town{
    background-color: #efefef;
    h2{
        margin-bottom: 3rem;
        margin-top: 5rem;
        padding-left: 1.25rem;
        border-left: 10px solid #2164b2;
    }
    .grid-2-col{
        grid-template-columns: 4fr 3fr;
        @include media-mobile-only {
            grid-template-columns: 1fr;
        }
        div{
            margin-bottom: 2rem;
        }
    }
}

.scroll-lock{
    .menu-toggle {
        z-index: 9999;
    }
}

.testimonial{
    .padding-section-content{
        column-count: 3;
        column-gap: 100px;  
        @include media-mobile-only {
            column-count: 1;
        }
    }
    &__wrap{
        padding-bottom: 6rem;
        margin-bottom: 10px;
        break-inside: avoid;
        &:after{
            content: ""; 
            width: 10%; 
            height: 1px; 
            position: relative; 
            bottom: -2rem; 
            background-color: #2164b2; 
            display: block;
        }
    }
    &__rating{
        display:flex; 
        max-width:100px; 
        max-width: 110px; 
        gap: 6px;
    }
}