.menu__gallery {
  //background-color: $color-black;
  padding: 5rem 4rem 5rem;
  //margin-bottom: 2rem;
  //max-width: 300px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: block;
      a {
        font-size: 16*$pxToRem;
        color: $color-white;
        text-transform: uppercase;
        line-height: 1;
        &:hover {
          color: $color-accent;
        }
      }
    }
  }
}

.menu__procs {
  background-color: $color-black;
  padding: 5rem 4rem 5rem;
  max-width: 300px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: block;
      a {
        font-size: 24*$pxToRem;
        color: $color-white;
        text-transform: capitalize;
        line-height: 3;
        &:hover {
          color: $color-accent;
        }
      }
    }
  }
}

.menu__intro {
  background-color: $color-black;
  position: absolute;
  //left: 0;
  top: 6rem;
  right: 0;
  //bottom: 0;
  z-index: 10;
  width: 100%;
  padding: 5rem 4rem 5rem;
  max-width: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  //margin: 3rem auto 0;
  @include media-below-desktop {
    top: -10rem;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: block;
      line-height: 2.5;
      a {
        font-size: 16*$pxToRem;
        color: $color-white!important;
        text-transform: uppercase;
        text-decoration: none!important;
        line-height: 1;
        &:hover {
          color: $color-accent;
        }
      }
    }
  }
}

.menu__white {
  background-color: $color-white;
  padding: 7rem;
  max-width: 100%;
  ul {
    column-count: 2;
    margin: 0;
    padding: 0;
    li {
      a {
        color: $color-black;
        font-weight: 700;
        text-transform: capitalize;
        &:hover {
          color: $color-accent;
        }
      }
    }
  }
}

.menu__services {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      margin: 0 2rem;
      position: relative;
      @include media-below-desktop {
        display: block;
        text-align: center;
        margin: 3rem 0;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:after {
        content: '';
        background-color: #ddd;
        width: 1px;
        height: 100%;
        position: absolute;
        right: -2rem;
        @include media-below-desktop {
          height: 1px;
          width: 40px;
          left: 0;
          right: 0;
          bottom: -25px;
          margin: 0 auto;
        }
      }
      a {
        color: $color-black;
        font-weight: 300;
        text-transform: uppercase;
        &:hover {
          color: $color-accent;
        }
      }
    }
  }
}