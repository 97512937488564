body {
  &.home {
    .section__banner {
      height: calc(100vh - 80px);
      max-height: inherit;
      background-size: cover;   
      background-position-y: top;  
      @include media-mobile-only {
          height: calc(80vh - 80px);
      }
      .svg__accent {
        svg {
          opacity: .3;
          z-index: 0;
        }
      }
      @include media-mobile-only {      
        &:after {
          background-color: rgba($color-black,.3);
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }
      &--block {
        h1 {
          color: $color-black;
          margin: 0 auto;
          @include media-mobile-only {
            color: $color-white;
          }
        }
        .title {
          font-size: 16*$pxToRem;
          &.sub{    
          @include media-mobile-only {
              display: none;
            }        
          }
        }
      }
    }
    .section__assoc {
      @include media-mobile-only {
        margin-top: 290px;
      }
      @include media-tablet-only {
        margin-top: 350px;
      }
    }
  }
  main {
    padding-top: 80px;
    overflow: hidden;
    @include media-mobile-only {
      padding-top: 60px;
    }
  }
}

.section__banner {
    height: calc(96vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-position: top;
    animation: fadeInAnimation ease 3s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
    @keyframes fadeInAnimation {
        0% {
            background-size: 130% auto;
            opacity: 0;
        }
        100% {
            background-size: 105% auto;
            opacity: 1;
        }
    }
  @include media-mobile-only {
    animation: none;   
  }
  @include media-below(1500){
    height: calc(85vh - 80px)!important;     
  }
  @include media-below(1300){
    height: calc(65vh - 80px)!important;     
  } 
  @include media-below(960){
    height: calc(55vh - 80px)!important;     
  }
  &.home-banner{
    @include media-mobile-only {
      height: calc(80vh - 80px)!important; 
      background-position-x: 40%;    
    }        
  }    
  .svg__accent {
    svg {
      opacity: .3;
      z-index: 0;
      max-width: 250px;
    }
  }
  &--block {
    z-index: 5;
    position: relative;
    //margin-top: 13rem;
    //margin-left: auto;
    //margin-right: auto;
    //margin-bottom: auto;
    @include media-tablet-only {
      //margin-top: 11rem;
    }
    //@include media-laptop-only {
    //  margin-top: 16rem;
    //}
    @include media-desktop-only {
      //margin-top: auto;
    }
    h1 {
      color: $color-white;
      margin: 0 auto;
    }
    .title {
      @include media-mobile-only {
        color: $color-white;
      }
    }
  }
  &--button {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 2rem;
    right: 2rem;
    bottom: 2rem;
    z-index: 10;
    @include media-mobile-only { 
      a {
        padding: .625rem 0.25rem;  
        &:nth-child(1) {
          color: $color-white;
          border-color: $color-white;
          &:hover {
            border-color: $color-black;
          }
        }
      }
    }
  }
  nav {
    ul, ol {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      font-size: 1.3125rem;
      gap: 1.6875rem;
      li {
        position: relative;
        font-family: $body-font;
        color: $color-white;
        font-size: 16*$pxToRem;
        font-weight: 600;
        font-style: normal;
        //letter-spacing: 0.05em;
        line-height: 1.7142857143;
        text-transform: uppercase;
        @include media-mobile-only {
          //font-size: 17*$pxToRem;
          line-height: 1.25;
        }
        &:not(:last-child) {
          &:before {
            display: block;
            position: absolute;
            right: -0.7875rem;
            top: 0.3rem;
            content: "|";
            width: 0.1875rem;
            height: 0.1875rem;
            color: $color-white;
            border-radius: 100%;
            @include media-mobile-only {
              top: 0.275rem;
            }
          }
        }
        a {
          color: $color-white;
          font-weight: 600;
          font-style: normal;
          display: block;
          padding: 0.3125rem 0.25rem;
          &:hover {
            color: $color-accent-light;
          }
          @include media-mobile-only {
            //font-size: 17*$pxToRem;
            line-height: 1.25;
          }
        }
      }
    }
  }
}

.section__blocks {
  .grid-3-col-1-3-3 {
    grid-gap: 100px;
    @include media-below-desktop {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
  }
  h2 {
    margin: 0;
    line-height: 1.4;
  }
  li {
    &::marker {
      color: $color-accent;
    }
  }
  &--intro {
    @include media-below-desktop {
      margin-top: 2rem!important;
      .grid-2-col {
        grid-template-columns: 1fr;
      }
      &-right {
        position: relative;
        margin-top: 10rem;
        @include media-mobile-only {
           margin-top: 5rem;   
        }
        margin-bottom: 4rem;
      }
    }
  }
  &--content {
    &-two {
      @include media-below-desktop {
        padding: 4rem 2rem 4.5rem!important;
      }
      .box-align-vertically {
        position: relative;
        z-index: 10;
      }
    }
  }
}

.section__welcome {
  overflow: hidden;
  .signature {
    margin-top: -20rem;
    margin-left: 50px;
    margin-bottom: 50px;
    z-index: 10;
    position: relative;
    @include media-tablet-only {
      margin-top: -16rem;
    }
  }
  .quote-box {
    //margin-top: -6rem;
  }
  &--page {
    .signature {
      margin-top: 3rem;
      margin-left: 0;
      margin-bottom: 0;
    }
    .quote-box {
      margin-top: -6rem;
      float: right;
    }
  }
  @include media-mobile-only {
    &--left {
      order: 2;
    }
    &--right {
      padding-top: 60px!important;
      padding-bottom: 65px!important;
      .bg-text {
        font-size: 60vw;
        left: -2rem;
        letter-spacing: 2rem;
        top: 2rem;
      }
    }
  }
  @include media-tablet-only {
    &--right {
      .bg-text {
        font-size: 40vw;
        left: -4rem;
        letter-spacing: 2rem;
        top: 2rem;
      }
    }
  }
}

.section__bxa {
  background-color: transparent;
  background-position: top right;
  background-size: 90%;
  @include media-mobile-only {
    background-position: bottom;
    background-size: 100%;
  }
  .cases{
    grid-template-columns: 1fr 1fr!important;
  }    
  &--page {
    background-size: 100%;
    .swiper-slide {
      .align-center {
        align-items: center!important;
        img {
          border: 0!important;
          margin: 0 40px;
        }
      }
    }
  }
  .bg-white {
    margin-bottom: -280px;
    padding-bottom: 0;
    @include media-mobile-only {
      margin-bottom: 0;
      padding-bottom: 0!important;
    }
    .title {
      margin-top: 4rem;
    }
  }
  .grid {
    height: 100%;
    @include media-mobile-only {
      padding: 0 40px;
    }
  }
  .swiper {
    padding-bottom: 50px;
    margin-bottom: -180px;
    @include media-mobile-only {
      margin-bottom: -200px;
      padding-top: 215px;
    }
    @include media-tablet-only {
      margin-bottom: 0;
    }
    .section__reviews--nav {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 70px;
    }
  }
  .swiper-slide {
    transition: all $transition-default;
    .align-center {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 100%;
      img {
        max-width: 245px;
        @include media-mobile-only {
            max-width: 125px;
        }
        border: 20px solid #fff;
        &:nth-child(2) {
          margin-left: -20px;
        }
      }
    }
  }
  .swiper-pagination {
    bottom: -5rem!important;
    .swiper-pagination-bullet {
      &.swiper-pagination-bullet-active {
        &:before {
          background: $color-black;
        }
      }
      &:before {
        background: $color-gray-2;
        border: 0;
        width: 40px;
        height: 4px;
        border-radius: 0;
      }
    }
  }
   img{
        width: 260px; 
        @include media-mobile-only {
            width: 100%;
        }
    }    
}

.section__assoc {
  .grid-4-col {
    @include media-below-desktop {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    div {
      display: flex;
      justify-content: center;
      img {
        width: auto;
        max-height: 125px;
      }
    }
  }
  &--awards {
    div {
      img {
        max-width: fit-content;
      }
    }
  }
}

.section__face {
  overflow: hidden;
  .bg-text {
    font-size: 292*$pxToRem;
    letter-spacing: 4rem;
    line-height: 1;
    left: -4rem;
    @include media-mobile-only {
      font-size: 40vw;
      letter-spacing: 1rem;
      top: -5rem;
      left: -2rem;
    }
  }
  @include media-mobile-only {
    &--left {
      margin-top: 70px;
      padding-top: 60px!important;
      padding-bottom: 65px!important;
    }
  }
}

.section__rhino {
  --header-height: 84px;
  --section-height: calc(100vh - var(--header-height));
  padding-bottom: 8%;

  @include media-mobile-only {
    --section-height: auto;  
  }

  &__main {
    height: var(--section-height);

    @include media-below(960) {
      padding-left: 3.9583333333vw;
    }

    &:last-of-type {
      position: relative;
      z-index: 10;
    }
  }
  
  .img-container {
    img:not(.texture) {
      height: var(--section-height);
      object-fit: cover;
    }
  }

  .section__facelift {
    height: var(--section-height);
    position: relative;
    z-index: 10;
    padding-top: 0;

    @include media-below(960) {
      padding-left: 3.9583333333vw;
    }
  }

  .section__rhino--right {
    height: var(--section-height);
    z-index: 5;
  }

  img {
    &:nth-child(1) {
      margin-left: 2rem;
      // margin-bottom: 2rem;

      @include media-below(960) {
        margin-left: unset;
      }
    }
  }
  .texture {
    max-width: 338px;
    position: absolute;
    left: -5rem;
    bottom: -7rem;
    z-index: -1;
    @include media-mobile-only {
      max-width: 188px;
      left: -2rem;
      bottom: -2rem;
      display: none;
    }
    @include media-tablet-only {
      max-width: 248px;
      left: -3rem;
      bottom: 2rem;
    }
  }
  &--right {
    margin-left: -6rem;
    @include media-mobile-only {
      margin-left: 0;
    }
    @include media-below-desktop {
      margin-top: 6rem;
    }
  }
}

.section__breast {
  &--content {
    padding-bottom: 0;
    @include media-mobile-only {
      order: 2;
    }
    @include media-below-desktop {
      padding-top: 140px!important;
      padding-bottom: 0!important;
    }
    .box-align-vertically {
      justify-content: flex-end;
      position: relative;
      z-index: 10;
    }
  }
}

.section__body {
  &--page {
    .video {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      svg {
        padding-right: 30px;
      }
    }
    .bg-white {
      padding-top: 10rem!important;
      padding-bottom: 10rem!important;
      margin-top: 200px!important;
      margin-left: -80px!important;
      margin-right: 100px!important;
      z-index: 10;
      position: relative;
      @include media-mobile-only {
        padding-top: 7rem!important;
        padding-bottom: 7rem!important;
        margin-top: 0!important;
        margin-left: 0!important;
        margin-right: 0!important;
      }
      @include media-tablet-only {
        padding-top: 5rem!important;
        padding-bottom: 5rem!important;
      }
    }
    &-right {
      .bg-white {
        padding-top: 10rem!important;
        padding-bottom: 10rem!important;
        margin-top: 150px!important;
        margin-left: 100px!important;
        margin-right: -80px!important;
        @include media-mobile-only {
          padding-top: 7rem!important;
          padding-bottom: 7rem!important;
          margin-top: 0!important;
          margin-left: 0!important;
          margin-right: 0!important;
        }
        @include media-tablet-only {
          padding-top: 5rem!important;
          padding-bottom: 5rem!important;
        }
      }
    }
  }
  .bg-white {
    padding-top: 10rem;
    padding-bottom: 0;
    margin-top: 80px;
    margin-left: -80px;
    margin-right: 100px;
    z-index: 10;
    position: relative;
    @include media-mobile-only {
      padding-top: 7rem!important;
      padding-bottom: 0!important;
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }
    @include media-tablet-only {
      padding-top: 5rem;
      margin-right: 40px;
    }
  }
}

.section__recon {
  .box-align-vertically {
    min-height: 40rem;
    position: relative;
    z-index: 10;
    @include media-below-desktop {
      min-height: 30rem;
      margin-bottom: 5rem;
      justify-content: flex-end;
    }
  }
}

.section__fly {
  overflow: hidden;
  .bg-text {
    font-size: 292*$pxToRem;
    letter-spacing: 4rem;
    line-height: 1;
    left: -2rem;
    @include media-mobile-only {
      font-size: 40vw;
      letter-spacing: 2rem;
      left: 6rem;
      top: 0;
    }
    @include media-below-desktop {
      font-size: 30vw;
    }
  }
  &--left {
    .box-align-vertically {
      max-width: 500px;
      @include media-mobile-only {
        margin-bottom: 6rem;
      }
    }
  }
  &--right {
    padding-right: 4rem;
    @include media-below-desktop {
      padding-right: 0;
      padding-bottom: 11rem;
    }
    img {
      margin-left: -6rem;
      @include media-below-desktop {
        margin-left: 0;
      }
    }
    .menu__procs {
      position: absolute;
      right: 0;
      bottom: 10rem;
      @include media-below-desktop {
        bottom: 0;
      }
    }
  }
}

.section__reviews {
  @include media-mobile-only {
    margin-top: 7rem;
  }
  .svg__accent {
    top: -6rem;
    z-index: -1;
  }
  .width-1920 {
    @include media-below-desktop {
      padding: 0 20px;
    }
  }
  &--block {
    padding-bottom: 8rem !important;
    @include media-mobile-only {
      margin-bottom: 0;
    }
    .rating {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 1rem;
        height: 20px;
        margin: 0 .1rem;
      }
    }
    .name {
      font-size: 22*$pxToRem;
      padding-left: 5rem
    }
    .swiper-slide {
      opacity: 0.15;
      transition: all $transition-default;
      &.swiper-slide-active {
        opacity: 1;
        a {
          color: $color-white;
          &:hover {
            color: $color-accent;
          }
        }
      }
    }
    .swiper-pagination {
      bottom: -5rem!important;
      .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          &:before {
            background: $color-black;
          }
        }
        &:before {
          background: $color-gray-2;
          border: 0;
          width: 40px;
          height: 4px;
          border-radius: 0;
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      bottom: 1rem;
      padding: 0;
      @include media-below(1200) {
        display: block;
      }
      svg {
        fill: $color-black;
      }
      &:hover {
        svg {
          fill: $color-accent;
        }
      }
    }
    .swiper-button-prev {
      left: 46%;
      transform: rotate(0deg);
      @include media-below(1200) {
        left: 44%;
      }
    }
    .swiper-button-next {
      right: 46%;
      transform: rotate(0deg);
      @include media-below(1200) {
        right: 44%;
      }
    }
  }
}

.section__blocks--gallery {
  @include media-below-desktop {
    margin-top: 8rem;
  }
  .grid-3-col {
    grid-gap: 100px;
    @include media-tablet-only {
      grid-template-columns: 1fr 1fr;
    }
    h2 {
      margin-top: -4rem;
    }
  }
}

.section__proc--tabs {
  background-color: $color-gray-11;
  overflow: hidden;
  .svg__accent {
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      opacity: .4;
    }
  }
  .grid-2-col-5-7 {
    grid-gap: 100px;
    padding: 8rem 5rem;
    position: relative;
    z-index: 10;
    @include media-mobile-only {
      grid-gap: 30px;
      padding: 6rem 2rem;
    }
    @include media-tablet-only {
      grid-gap: 60px;
      padding: 8rem 2rem;
    }
  }
  .tabs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 30px;
    @include media-mobile-only {
      padding: 0;
      line-height: 30px;
    }
    @include media-tablet-only {
      padding: 0;
      line-height: 30px;
    }
  }
  .tab-link {
    font-family: $body-font;
    font-size: 24*$pxToRem;
    color: rgba($color-white,.5);
    list-style: none;
    text-transform: capitalize;
    cursor: pointer;
    margin: 0 0 2rem;
    transition: all $transition-default;
    @include media-mobile-only {
      font-size: 20*$pxToRem;
    }
    @include media-tablet-only {
      font-size: 25*$pxToRem;
    }
    &:hover {
      color: $color-white;
    }
    &.current {
      color: $color-white;
    }
  }
  .tab-content {
    color: $color-white;
    font-size: 17*$pxToRem;
    display: none;
    opacity: 0;
    transition: all $transition-default;
    &.current {
      display: block;
      opacity: 1;
    }
  }
}

.section__ratings {
  .grid-3-col {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      @include media-mobile-only {
        display: block;
      }
    }
  }
  img {
    max-height: 82px;
    width: auto;
    margin: 0 auto;
    @include media-mobile-only {
      width: 16%;
      margin: 0 auto 2rem;
    }
  }
  p {
    font-size: 26*$pxToRem;
    margin-top: 3rem;
  }
}

.section__events {
  @include media-mobile-only {
    .grid-2-col-5-7 {
      grid-template-columns: 5fr 7fr;
    }
  }
  .hide {
    @include media-desktop-only {
      display: none;
    }
  }
  a {
    p {
      color: $color-white;
      font-weight: 700;
      text-transform: uppercase;
      overflow: hidden;
      padding-bottom: 10px;
      &:after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        background-color: $color-accent;
        width: 100%;
        height: 7px;
        z-index: 1;
        opacity: 1;
        transition: all $transition-default;
        transform: translateX(0);
      }
    }
    img {
      @include media-below-desktop {
        height: auto!important;
      }
      @include media-below(1200) {
        object-fit: cover;
        width: 100%;
        max-width: 100%;
        height: 100%;
      }
    }
    &:hover {
      img {
        opacity: .7;
        transform: scale(1.1);
      }
      p {
        color: rgba($color-white,.6);
        &:after {
          background-color: $color-accent;
          opacity: 0;
          transform: translateX(100%);
        }
      }
    }
  }
  &--events {
    overflow: hidden;
    p {
      position: absolute;
      top: 2rem;
      left: 2rem;
      @include media-below-desktop {
        display: none;
      }
    }
  }
  &--specials {
    overflow: hidden;
    p {
      position: absolute;
      bottom: 2rem;
      left: 2rem;
      @include media-below-desktop {
        display: none;
      }
    }
  }
}

.section__appointment {
  .grid {
    min-height: 30rem;
    @include media-mobile-only {
      min-height: 20rem;
    }
      a {
        color: $color-white;
        &:hover {
          color: rgba($color-white,.7);
        }
      }
  }
  .relative {
    z-index: 10;
    @include media-below-desktop {
      margin: 0 auto;
    }
  }
}


.section__info {
  .grid-3-col {
    grid-gap: 50px;
    @include media-below-desktop {
      grid-template-columns: 1fr;
    }
  }
  .section__info--box {
    @include media-mobile-only {
      grid-template-columns: 5fr 7fr;
    }
    .align {
      //padding: 0.5rem 0 1rem;
      margin: 0 2rem;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @include media-laptop-only {
        margin: 0 0 0 1rem;
      }
    }
    p {
      margin: 1rem 0;
    }
    a {
      text-transform: uppercase;
    }
    &:hover {
      a {
      }
    }
  }
}

.section__menu {
  @include media-below-desktop {
    .grid-2-col-5-7,
    .grid-2-col-7-5 {
      grid-template-columns: 1fr;
    }
    .padding-left-default,
    .padding-right-default {
      padding: 0;
    }
    .menu-align {
      margin-top: 3rem;
    }
    &--left {
      .menu-align {
        order: 2;
      }
    }
  }
}

.section__contact {
  background-color: $color-gray-6;
  &--column {
    position: relative;
    z-index: 10;
    min-height: 24rem;
    &-align {
      margin-top: 2rem;
      margin-left: 2rem;
      margin-right: 2rem;
      @include media-tablet-only {
        margin-top: -4rem;
        margin-left: 0;
        margin-right: 4rem;
      }
      @include media-laptop-only {
        margin-top: -4rem;
        margin-left: 0;
        margin-right: 4rem;
      }
      @include media-above-laptop {
        margin-top: -4rem;
        margin-left: 0;
        margin-right: 6rem;
      }
    }
    .title {
      a {
        color: $color-white;
        &:hover {
          color: $color-white;
        }
      }
    }
  }
}

.section__resources {
  .width-1640 {
    .grid-2-col {
      grid-gap: 30px;
    }
  }
  .width-1330 {
    &.grid-4-col {
      grid-gap: 20px;
    }
  }
  &--box {
    background-color: $color-gray-4;
    color: $color-white;
    cursor: pointer;
    font-size: 16*$pxToRem;
    font-weight: 600;
    min-height: 90px;
    padding: 3rem 4rem 4rem;
    position: relative;
    z-index: 10;
    overflow: hidden;
    text-transform: uppercase;
    &:before {
      background-color: $color-accent;
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: all $transition-default;
      width: 10px;
    }
    &:hover {
      &:before {
        width: 100%;
      }
      .relative {
        svg {
          opacity: 0.3;
        }
      }
    }
    &-align {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 10;
      svg {
        margin-top: 0;
        display: block;
      }
    }
    .relative {
      svg {
        fill: $color-white;
        opacity: 0.08;
        position: absolute;
        right: -3rem;
        top: -0.5rem;
        transition: all $transition-default;
      }
    }
  }
}

.location__box {
  background-color: $color-gray-6;
  width: 50%;
  max-width: 520px!important;
  padding: 4rem 3rem;
  margin: -7rem auto 5rem;
  z-index: 10;
  position: relative;
  h2 {
    font-family: $body-font;
    font-size: 24*$pxToRem;
    color: $color-white;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  p {
    margin-top: 0;
    a {
      color: $color-white;
      font-weight: 400;
      &:hover {
        color: $color-accent;
      }
    }
  }
}

.section__footer {
  background-color: $color-gray-11;
  @include media-mobile-only {
    padding-bottom: 60px;
  }
  @include media-below-desktop {
    padding-top: 120px;
  }
  .grid-2-col-3-8 {
    z-index: 10;
    position: relative;
    @include media-below-desktop {
      grid-template-columns: 1fr;
    }
    svg {
      z-index: -1;
    }
  }
  &--column {
    min-height: 26rem;
    z-index: 10;
    display: flex;
    flex-direction: column;
    .grid-4-col {
      margin: auto 0;
      @include media-mobile-only {
        grid-template-columns: 1fr 1fr;
      }
      @include media-below-desktop {
        margin: 2rem 0 4rem;
      }
      .title {
        margin: 0 0 0.5rem;
        @include media-below-desktop {
          margin: 2rem 0 0.5rem;
        }
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          a {
            font-weight: 300;
            text-transform: uppercase;
            &:hover {
              color: $color-accent-light;
            }
          }
        }
      }
    }
    a {
      color: $color-white;
      transition: all $transition-default;
      &:hover {
        color: $color-accent-light;
      }
      &.phone {
        display: block;
        margin: 0 0 0.8rem;
      }
    }
  }
  .bg-tinted-more {
    &:after {
      background: rgba($color-black,.7);
    }
  }
}

#financing{
    svg{    
      @include media-mobile-only {
          width: 100%;     
      }
    }
}