/* gallery module: theme */

.section{
    &__gallery {
        .btn {
            &__gallery {
                min-height: unset;
                padding: 0;
                margin: 1rem auto 0 auto;
                display: block;

                @include media-mobile-only {
                    padding: 0.5rem;
                }
            }

            @include media-mobile-only { 
                min-width: 12rem;
                margin-bottom: 1rem;
            }
        }
    }

    &__gallery-home {
        .grid-3-col,
        .grid-2-col {
            gap: 2rem;
        }

        .gallery-menu-wrapper {
            max-width: 300px;
            min-height: 300px;
            background-image: url('/files/2023/05/texture-01.webp');
            background-position: center;
            background-size: cover;
            margin: 0 auto;
        }

        h2{
            text-align: center;
            padding-bottom: 2rem;
        }

        ul{
            list-style-type: none;
            padding-left: 0;

            li{
                text-transform: uppercase;

                a{
                    color: #FFF;

                    &:hover{
                        color: $overlay-blue;
                    }
                }

                @include media-mobile-only { 
                    padding: 0.35rem;
                }
            }
        }
    }

    &__gallery-listing{

    }

    &__gallery-single{
    
    }
}

.case-box__images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;

    .term-facelift & {

        img {

        @media (min-width: 1025px) {
           max-height: 14vw;
        }

    }

    }
}

.no-link {
    color: #b4b4b4;
    cursor: default;
}

.no-link:hover {
    color: #b4b4b4;
}

.gallery-titles span {
    display: block;
    font-size: 18*$pxToRem;
}
.procedure-link {
    justify-self: right;
}
.patient-navigation {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

@include media-mobile-only {
    .gallery-links {
        justify-items: center;           
    }
    .procedure-link {
        justify-self: center;
    }
}