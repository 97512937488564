.home-banner {
  max-height: 1080*$pxToRem;

  .logo {
    width: 536*$pxToRem;
    height: 292*$pxToRem;

    @include media-mobile-only {
      width: 70%;
      height: auto;
      margin: auto;
    }
  }
}