/* bg module: theme */

.relative {
  position: relative;
}
.bg-gray {
  background-color: $bg-gray;
}
.bg-white {
  background-color: $color-white;
}

.box {
  &-align {
    &-vertically {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
    &-height {
      height: auto;
    }
  }
  &-quote {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-tablet-only {
      width: 80px;
      height: 80px;
      top: -3rem;
      svg {
        width: 2.5rem;
      }
    }
  }
}

.quote-box {
  background-color: $color-white;
  padding: 4rem;
  max-width: 350px;
  position: relative;
  z-index: 10;
  svg {
    width: 4rem;
  }
  p {
    font-size: 24*$pxToRem;
    line-height: 32*$pxToRem;
  }
}

.bar {
  background-color: $color-black;
  width: 130px;
  height: 10px;
  display: inline-block;
  margin-right: 25px;
  &.inverted {
    background-color: $color-white;
  }
}